<template>
  <div>
    <portal to="v-main">
      <component
        v-if="cashType && showEditDialog"
        :is="cashType"
        v-model="showEditDialog"
        :id="idEdit"
        :config="configDoc"
        :api="url"
        :initData="initData"
        :document="parentDoc"
        @selectType="setImportOperation($event)"
      />
    </portal>
  </div>
</template>

<script>
import { getAccess, modelOptions, keyDetect } from "@/components/mixings";
import libsCash from "./../libsCash";
export default {
  components: {
    cashRaw: () => import("./cashRaw/editDialog"),
    cashOrder: () => import("./cashOrder/editDialog"),
    cashImport: () => import("./cashImport/editDialog"),
    cashParent: () => import("@/views/documents/dialogs/cashParent/editDialog"),
    cashCashBox: () => import("./cashCashBox/editDialog"),
  },
  mixins: [getAccess, modelOptions, libsCash, keyDetect],
  props: {},
  data() {
    return {
      url: null,
      idEdit: null,
      parentDoc: null,
      operation_type: null,
      showEditDialog: false,
      initData: null,
    };
  },
  computed: {},
  created() {},
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.cashType = null;

        this.$emit("updateData");
      }
    },
  },
  methods: {
    setImportOperation({ type, id }) {
      console.log("setImportOperation", { type, id });
      this.$nextTick(() => {
        this.openDialog(type, id);
      });
    },
    async openDialog(type, id, data = {}, initData = null) {
      let url;
      let model;
      this.initData = initData;
      console.log("init data cash ", initData);
      if (typeof type === "string") {
        if (type == "AccountingDocOrderWithdrawalModel") url = "accounting/doc/order_withdrawal";
        if (type == "AccountingDocOrderReceiptModel") url = "accounting/doc/order_receipt";
        model = type;
        if (!url || !id) {
          this.$root.$emit("show-info", {
            type: "error",
            text: "Документ не настроен",
          });
          return;
        }
        let resp = await this.$axios.get(url + "/" + id);
        let d = resp?.data?.data || null;
        type = d?.operation_type || null;
        if (!type && d && d.import_id) {
          await this.onConfigDoc(-1, model);
          this.cashType = "cashImport";
          console.log("cashImport", this.configDoc, model);
          this.showEditDialogFun(id);
          return;
        }
      }

      //console.log("open dialog", type, url);
      this.operation_type = type;
      let getConfig = await this.onConfigDoc(this.keys?.ctrl ? -1 : this.operation_type, model);
      if (!getConfig) {
        console.log("Документ не настроен", { type, id, data, getConfig });
        this.$root.$emit("show-info", {
          type: "error",
          text: "Документ не настроен",
        });
        return;
      }
      this.parentDoc = data;

      this.url = this.configDoc.m.api;
      this.showEditDialogFun(id);
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.showEditDialog = true;
    },
  },
};
</script>
